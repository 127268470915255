import React from 'react';
import moment from 'moment';
import useConsumedCreditsStart from '../../hooks/useConsumedCreditsStart';
import {Alert} from '@material-ui/lab';

const HistoricalDataAlert = ({publisherStats}) => {
  const consumedCreditsStart = useConsumedCreditsStart();
  const lastStatsDate = publisherStats?.length > 0 ? [...publisherStats].pop()?.date : null;
  const historicalDataLoaded = moment(lastStatsDate).isBefore(consumedCreditsStart?.startDate);

  if (consumedCreditsStart && historicalDataLoaded) {
    return (
      <Alert severity="warning">
        This is historical data retrieved from your GAM account. Setupad data will be available once
        the integration is completed and the tags are live on the website.
      </Alert>
    );
  } else {
    return null;
  }
};

export default HistoricalDataAlert;
